<template>
  <v-container
    id="login"
    class="fill-height"
    tag="section"
  >
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="primary"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template #heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold">
                Logout
              </h1>
            </div>
          </template>

          <v-card-text class="text-center">
            <v-progress-circular
              indeterminate
            />
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'PagesLogout',

    data: () => ({
      socials: [],
    }),
    created () {
      this.logout()
    },
    methods: {
      logout: function () {
        this.$auth.logout()
      },
    },
  }
</script>
